import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Flex } from 'grid-styled'
import CTA from '../../components/CTA'
import GalleryContainer from '../../components/GalleryContainer'
import GalleryImage from '../../components/GalleryImage'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
} from '..'
import Form from '../../components/Form'
import { Text, GallerySection, Content } from '../../components/styles'
import Button from '../../components/Button'
import ContentCard from '../../components/ContentCard'
import Hero from '../../components/Hero'
import { Video } from '../../components/procedures'

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Flyover Patio | Brisbane Patios | Just Patios</title>
      <meta
        name="description"
        content="Professional flyover patio builders servicing Brisbane and the Sunshine Coast. Custom residential & commercial flyover patios. Contact us today for an obligation free design & quote."
      />
    </Helmet>

    <Content style={{ paddingTop: 150 }}>
      <h1>Flyover Patio</h1>

      <h4>
        This patio design has grown more and more popular, it's modern design
        and extra height could be the perfect compliment to your home.
      </h4>
      <p>
        A flyover patio is a design which over the years has grown more popular
        with home owners. This design is elevated above your existing gutter
        line creating air flow and extra height. The added height gives you the
        feeling of space and allows extra natural lighting to filter through.
        This design can be built with both insulated and non-insulated roofing
        products. If you want the feeling of space and light this option is
        ideal and a great alternative.
      </p>

      <Link to="/contact-us#form">
        <Button inverted={true}>Request Your Free Quote Today!</Button>
      </Link>
    </Content>

    <GalleryContainer>
      {data.gallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          const arr = x.node.childImageSharp.fixed.src.split('/')
          const number = parseInt(arr[arr.length - 1].split('.')[0])
          const arr2 = y.node.childImageSharp.fixed.src.split('/')
          const number2 = parseInt(arr2[arr2.length - 1].split('.')[0])

          return number < number2 ? -1 : 1
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return false
          }
          const arr = image.node.childImageSharp.fixed.src.split('/')
          const name = arr[arr.length - 1].split('.')[0]
          if (name === 'cover') {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fixed={image.node.childImageSharp.fixed} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>

    <VideoContainer style={{ marginTop: -100 }}>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query FlyOverQuery {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/patios/flyover" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
